export default function headerSize() {
  const header = document.getElementById('header');
  let isShrink = false;

  window.addEventListener('scroll', function() {
    if (window.scrollY > 0 && !isShrink) {
      header.classList.add('shrink');
      isShrink = true;
    } else if (window.scrollY === 0 && isShrink) {
      header.classList.remove('shrink');
      isShrink = false;
    }
  });
}
