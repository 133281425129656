import AOS from "aos";
import 'aos/dist/aos.css';

const scrollAnimation = () => {
    const mobileWidth = 767;
    AOS.init({
        delay: 200,
        duration: 600,
        once: true,
        disable: function () {
            return mobileWidth >= window.innerWidth;
        },

    });
};

export default scrollAnimation;
