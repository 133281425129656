import {toggleMobileMenu, hideMenu} from "./modules/toggleMobileMenu";
import scrollAnimation from "./modules/aosAnim";
import fancyPopUp from "./modules/fancyPopUp";
import headerSize from "./modules/header";
import customSelect from "./utils/customSelect";
import {stickyBannerTop} from "./modules/banner";
import {debounce} from "./utils/delays";


const btnBurgerMenu = document.querySelector('.header__burger');
const navMobile = document.querySelector('.header .header__navigation');
const btnScroll = document.querySelector('.hero__scroll');
const banner = document.querySelector('.banner');


window.addEventListener('DOMContentLoaded', () => {
    banner.style.display = 'flex';
    banner.style.height = '100px';
    stickyBannerTop();
});

window.addEventListener('load', () => {
    scrollAnimation();
    headerSize();
    toggleMobileMenu(btnBurgerMenu, navMobile);
    btnScroll?.addEventListener('click', () => {
        btnScroll?.closest('.hero').nextElementSibling?.scrollIntoView({behavior: 'smooth'});
    })
    fancyPopUp();

    customSelect.initAll();
});

window.addEventListener('resize', () => {
    if (window.innerWidth >= 1200) debounce(hideMenu(btnBurgerMenu, navMobile), 400);
});

