export const stickyBannerTop = () => {
  const body = document.body;
  const banner = body.querySelector('.banner');
  const bannerType  = banner.getAttribute('data-type');
  const btnCloseBanner = body.querySelector('.banner__btn');
  const header = body.querySelector('.header');
  const main = body.querySelector('.main');
  const filterButtons = body.querySelector('.find-your-home-map__filter');

  function hideBanner () {
    banner.style.display = "none";
    banner.style.height = 0;
    header.classList.remove('header--banner');
    main?.classList.remove('main--banner');
    filterButtons ? filterButtons.style.top = "80px" : 0;
  }

  if (sessionStorage.getItem(`${bannerType}`) === 'closed') hideBanner();

  btnCloseBanner.addEventListener("click", function() {
    hideBanner();
    sessionStorage.setItem(`${bannerType}`, 'closed');
  });
}

