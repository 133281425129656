import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const fancyPopUp = () => {
    Fancybox.bind('.js-hop-popup', {
        Html: {
            video: {
                autoplay: true,
                buttons: {},
                arrows: false,
            },
        },
    });
}

export default fancyPopUp;
