export const hideMenu = (btn, nav) => {
  document.body.classList.remove('disable-scroll');
  btn.classList.remove('close');
  nav.classList.remove('show');

  const activeSubmenus = nav.querySelectorAll('.submenu.active');
  activeSubmenus.forEach(submenu => submenu.classList.remove('active'));
};

export const toggleMobileMenu = (btn, nav) => {
  if (!btn || !nav) return;

  const handleButtonClick = () => {
    if (!btn.classList.contains('close')) {
      document.body.classList.add('disable-scroll');
      btn.classList.add('close');
      nav.classList.add('show');
    } else {
      hideMenu(btn, nav);
    }
  };

  const handleNavClick = (e) => {
    const target = e.target;
    if(target.closest('.menu-item:not(.menu-item-has-children)')) hideMenu(btn, nav);
    if (target.tagName.toLowerCase() === 'a') {
      if (target.getAttribute('href') === '#') {
        e.preventDefault();
      } else if (!target.classList.contains('menu-item-has-children') && !target.closest('.menu-item-has-children')) {
        return;
      }
    }

    const menuItem = target.classList.contains('menu-item-has-children') ? target : target.closest('.menu-item-has-children');
    if (menuItem) {
      const submenu = menuItem.querySelector('.submenu');
      if (submenu) {
        submenu.classList.toggle('active');
      }
    }
  };

  const handleResize = () => {
    hideMenu(btn, nav);
    attachMenuEvents();
  };

  const attachMenuEvents = () => {
    btn.removeEventListener('click', handleButtonClick);
    nav.removeEventListener('click', handleNavClick);
    window.removeEventListener('resize', handleResize);

    btn.addEventListener('click', handleButtonClick);

    if (window.innerWidth <= 1200) {
      document.getElementById('lastLink').addEventListener('keydown', function (event) {
        if (event.key === 'Tab' && !event.shiftKey) {
          event.preventDefault();
          btn.focus();
        }
      });

      nav.addEventListener('click', handleNavClick);
    }

    window.addEventListener('resize', handleResize);
  };

  attachMenuEvents();
};
